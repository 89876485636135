import React from 'react';
import './Footer.css';

export const Footer = () => {
    return (
        <footer>
            <div className='footer__copyright'>
                <span className='text-base md:text-xl font-bold md:text-base'>{new Date().getFullYear()} © Yu Thánh Thiện</span>
                <span className='text-sm'>Design by KD</span>
            </div>
            <div className='footer__social'>
                <a className='text-base md:text-lg font-medium' href='https://ca8t.short.gy/yugei-pagemoi'><i className='pi pi-facebook pi-fw' /> Page mới của Yu</a>
                <a className='text-base md:text-lg font-medium' href='https://ca8t.short.gy/yugei-pagexemmoi'><i className='pi pi-facebook pi-fw' /> Page xém mới của Yu</a>
                <a className='text-base md:text-lg font-medium' href='https://ca8t.short.gy/yugei-group'><i className='pi pi-facebook pi-fw' /> Group kín của Yu</a>
            </div>
        </footer>
    )
}