import './Popup.css';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';

const AdsKeeper = () => {
    const bannerRef = useRef();

    const modifiedStyle = '.mgbox { background: #272727 !important; } .mcimg { filter: brightness(20%) contrast(90%) } .mgbox .mctitle a { color: #333333 !important; }';

    // useEffect(() => {
    //     if(!bannerRef.current) return;
    //     const banner = bannerRef.current;

    //     const setStyle = () => {
    //         if(banner.classList.contains('modified')) return;

    //         const style = document.createElement('style');
    //         style.innerHTML = modifiedStyle;

    //         banner.shadowRoot.appendChild(style);

    //         banner.classList.toggle('modified');
    //     }

    //     const interval = setInterval(() => {
    //         if(banner.shadowRoot) {
    //             clearInterval(interval);
    //             setStyle()
    //         }
    //     }, 500)

    //     return () => clearInterval(interval);
    // }, [])

    return (
        <>
        <Helmet>
            <script src="https://jsc.adskeeper.co.uk/y/u/yuthanhthien.top.1402401.js" async></script>
        </Helmet>
        <div id="M851276ScriptRootC1402401" ref={bannerRef}></div>
        </>
    )
}

const PopupItem = ({ image, url, setInfo }) => {
    const isMobile = useMediaQuery({ maxWidth: '768px' });
    const isLegacyPhone = useMediaQuery({maxHeight: '650px'});

    const closePopup = (e) => setInfo('hide')

    const justifyContent = useMemo(() => {
        let style;
        const ad = document.getElementById('yuthanhthien.top_22966_320x50_624e1836dc2aa');

        if(isMobile) {
            if(isLegacyPhone) style = 'flex-start';
            else if(image && url && ad && ad.style.display !== 'none') style = 'flex-end';
            else style = 'center';
        }

        return {justifyContent: style}
    }, [isLegacyPhone, isMobile, image, url])

    return (
        <>
            <Helmet>
                <script key='gpt-script' id='gpt-script' async='async' src='https://www.googletagservices.com/tag/js/gpt.js'></script>
                <script type="text/javascript">{
                    `function configPopupBanner() {
                        googletag.defineSlot('/25379366/22713570489', [336, 280], 'yuthanhthien.top_22966_320x50_624e1836dc2aa').addService(googletag.pubads());
                        googletag.pubads().addEventListener('slotRenderEnded', function(event) {
                            const popupImage = document.getElementById('popup-image');
                            const slotId = event.slot.getSlotElementId();

                            if(slotId === 'yuthanhthien.top_22966_320x50_624e1836dc2aa' && event.isEmpty) {
                                const popup = document.getElementById('popup');

                                document.getElementById('yuthanhthien.top_22966_320x50_624e1836dc2aa').style.display = 'none';
                                popup.style.justifyContent = 'center';
                            }
                        });
                        googletag.pubads().enableSingleRequest();
                        googletag.enableServices();
                    }

                    function displayPopupBanner() {
                        googletag.display('yuthanhthien.top_22966_320x50_624e1836dc2aa');
                    }

                    function pushPopupBanner() {
                        if (window.googletag && googletag.apiReady) {
                            const slots = googletag.pubads().getSlots();

                            if(slots.length && slots.length > 0) { 
                                googletag.pubads().refresh();
                                return;
                            }

                            configPopupBanner();
                            displayPopupBanner();
                            return;
                        }

                        window.googletag = window.googletag || {cmd: []};
                        googletag.cmd.push(configPopupBanner);
                        
                        googletag.cmd.push(displayPopupBanner);
                    }
                    
                    pushPopupBanner();`
                }</script>
            </Helmet>
            <div id='popup' onClick={closePopup} style={justifyContent}>
                <div className='popup-container'>
                    <Button style={{fontSize: !isMobile && (!image || !url) ? '1rem' : undefined}} icon='pi pi-times' label='Đóng' onClick={closePopup} />
                    {(image && url)
                        ? <a id='popup-image' href={url}><img src={image} alt='' /></a>
                        : <AdsKeeper />
                    }
                </div>
                {(image && url) && <AdsKeeper />}
            </div>
        </>
    )
}

export const Popup = () => {
    const { pathname } = useLocation();
    const [info, setInfo] = useState();

    const getPopup = async () => {
        try {
            const res = await fetch('/api/popup')
            if (res.status === 200) {
                const popup = await res.json()
                if (popup) setInfo(popup)
            }
        } catch (err) {
            if (process.env.DEBUG) console.log(err)
        }
    }

    //eslint-disable-next-line
    useEffect(() => getPopup(), [])

    //eslint-disable-next-line
    useEffect(() =>  getPopup(), [pathname])

    const popup = useMemo(() => {
        if(info === 'hide') return null;

        const { image, url } = info || {};
        const options = { image, url, setInfo };

        if(!image || !url) return null;
        
        return <PopupItem {...options} />
    }, [info])

    return popup;
}