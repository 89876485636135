import './Maintenance.css'
import React from 'react';

export const Maintenance = () => {
    return (
        <div id='maintenance'>
            <img src='/logo512.png' alt='' />
            <h1>Khoan! Dừng khoảng chừng là 2s</h1>
            <h2>Website đang được bảo trì và nâng cấp, vui lòng quay lại sau.</h2>
        </div>
    )
};
