import './Ads.css'
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

const AAds = ({ items }) => {
    const AdTemplate = ({ id }) => {
        return <iframe title={id} className='qc__aa__banner' data-aa={id} src={'//acceptable.a-ads.com/' + id + '?size=Adaptive&background_color=272727'}></iframe>;
    }

    const banners = items.map(item => {
        return <AdTemplate key={item} id={item} />
    })

    return (
        <div className='qc__aa'>
            {banners}
        </div>
    )
}

const OtherAds = () => {
    const banner1Ref = useRef();
    const banner2Ref = useRef();

    const modifiedStyle = '.mgbox { background: #272727 !important; } .mcimg { filter: brightness(20%) contrast(90%) }';

    // useEffect(() => {
    //     if(!banner1Ref.current || !banner2Ref.current) return;
    //     const banner1 = banner1Ref.current;
    //     const banner2 = banner2Ref.current;

    //     const setStyle = () => {
    //         if(banner1.classList.contains('modified') && banner2.classList.contains('modified')) return;

    //         const style1 = document.createElement('style');
    //         const style2 = document.createElement('style');
    //         style1.innerHTML = modifiedStyle;
    //         style2.innerHTML = modifiedStyle;

    //         banner1.shadowRoot.appendChild(style1);
    //         banner2.shadowRoot.appendChild(style2);

    //         banner1.classList.toggle('modified');
    //         banner2.classList.toggle('modified');
    //     }

    //     const interval = setInterval(() => {
    //         if(banner1.shadowRoot && banner2.shadowRoot) {
    //             clearInterval(interval);
    //             setStyle()
    //         }
    //     }, 500)

    //     return () => clearInterval(interval);
    // }, [])

    return (
        <>
            <Helmet>
                <script src="https://jsc.adskeeper.co.uk/y/u/yuthanhthien.top.1402401.js" async></script>
                <script src="https://jsc.adskeeper.co.uk/y/u/yuthanhthien.top.1402404.js" async></script>
            </Helmet>
            <div className='qc__cus__banner' id="M851276ScriptRootC1402401" ref={banner1Ref} />
            <div className='qc__cus__banner' id="M851276ScriptRootC1402404" ref={banner2Ref} />
        </>
    )
}

const BannerTemplate = ({ banner, style }) => {
    const { image, url } = banner || {};

    if (image && url)
        return (
            <a className='qc__cus__banner' href={url} style={style}>
                <img src={image} alt='' />
            </a>
        )

    return <></>
}

const Banner = ({ items }) => {
    const MARGINS = ['margin-right', 'margin-left'];
    const isEmpty = items.every(item => (!item.image || !item.url));
    const hasTwo = items.every(item => (item.image && item.url));

    if (isEmpty) return <OtherAds />


    const banner = items.map((item, index) => {
        const style = hasTwo ? {[MARGINS[index]]: 'auto'} : undefined;

        return <BannerTemplate key={index} banner={item} style={style} />
    })

    return banner
}

const AdsContainer = ({ ads }) => {
    const [loaded, setLoaded] = useState(false);

    const {
        banner1, banner2, aads1, aads2, aads3
    } = ads || {};

    const options = {
        style: { opacity: loaded ? undefined : 0 },
        onLoad: () => setLoaded(true),
    }

    return (
        <>
            <Helmet>
                <script id="gpt-script" async='async' src='https://www.googletagservices.com/tag/js/gpt.js'></script>
            </Helmet>
            <div id='qc' {...options}>
                <AAds items={[aads1, aads2, aads3]} />
                <Banner items={[banner1, banner2]} />
            </div>
        </>
    )
}

export const Ads = () => {
    const [ads, setAds] = useState();

    const getAds = async () => {
        try {
            const res = await fetch('/api/ads')
            const ads = res.status === 200 ? await res.json() : null;
            if (ads) setAds(ads);
        }
        catch (err) {
            if (process.env.DEBUG) console.log(err)
        }
    }

    useEffect(() => getAds(), []) //eslint-disable-next-line

    const adBanner = useMemo(() => {
        if (ads) return <AdsContainer ads={ads} />
        else return <></>
    }, [ads])

    return adBanner
}